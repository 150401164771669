/**
 * @file Manage all the fetch operations, callbacks and errors
*/

/**
 * @function _fetch Manage a synchronous fetch operation
 * 
 * @param {string} _url - URL to fetch
 * @param {string} _method - Method to use
 * @param {Object|null} _body - Body if needed
 * @param {string} _body.test - Test text
 * @param {function|null} _onSuccess - Function to execute on success of both fetch and returned status
 * @param {function|null} _onError - Function to execute on wether fetch fail or error returned status
 * @param {Element|null} _elem - Elem attached to that action
 * @param {"json"|"blob"|"text"} _retour_type - Return type of back-office
 * @returns {boolean|Object} Wether the all process worked or not
*/
const _fetch = (
    _url = "/",
    _method = "GET",
    _body = {},
    _onSuccess = null,
    _onError = null,
    _elem = null,
    _retour_type = "json",
) => {
    if(
        isString(_url) &&
        isString(_method) &&
        isObject(_body) &&
        (isFunction(_onSuccess) || isNull(_onSuccess)) &&
        (isFunction(_onError) || isNull(_onError)) &&
        (isNull(_elem) || isElement(_elem)) &&
        isString(_retour_type)
    ) {
        let vars = {
            method: _method,
            headers: new Headers({}),
            mode: 'cors',
            cache: 'default',
        };
        if(_method != "GET") {
            vars = {...vars, body: JSON.stringify(_body)};
        }
        if(!_body.req_id) {
            _body.req_id = chaineAleatoire(8);
        }
        fetch(_url, vars)
        .then((res) => {
            switch(_retour_type) {
                case 'text':
                    // _log("text");
                    return res.text(); // Au cas où
                case 'blob':
                    // _log("blob");
                    return res.blob(); // TODO - Pour plus tard, upload et download de gros fichiers
                case 'json':
                default:
                    // _log("json");
                    return res.json();
            }
        })
        .then((response) => {
            if(isFunction(_onSuccess)) {
                _onSuccess(_elem, response);
                return true;
            }
            else {
                window.sessionStorage.setItem(_body.req_id + '_status', 'success');
                window.sessionStorage.setItem(_body.req_id + '_elem', _elem);
                window.sessionStorage.setItem(_body.req_id + '_res', JSON.stringify(response));
            }
            return false;
        })
        .catch((err) => {
            _trace(err);
            _error(err);
            if(isFunction(_onError)) {
                _onError(_elem, err);
            }
            else {

            }
            window.sessionStorage.setItem(_body.req_id + '_status', 'error');
            window.sessionStorage.setItem(_body.req_id + '_elem', _elem);
            window.sessionStorage.setItem(_body.req_id + '_res', JSON.stringify(err));
            return false;
        })
    }
    else {
        let _retour = {};
        _retour = isString(_url) == true ? _retour : {..._retour, _url: '_url was not from type string'};
        _retour = isString(_method) == true ? _retour : {..._retour, _method: '_method was not from type string'};
        _retour = isObject(_body) == true ? _retour : {..._retour, _body: '_body was not from type object'};
        _retour = (isFunction(_onSuccess) || isNull(_onSuccess)) == true ? _retour : {..._retour, _onSuccess: '_onSuccess was not from type function or null'};
        _retour = (isFunction(_onError) || isNull(_onError)) == true ? _retour : {..._retour, _onError: '_onError was not from type function or null'};
        _retour = isElement(_retour_type) == true || isNull(_elem) ? _retour : {..._retour, _elem: '_elem was not from type Element or null'};
        _retour = isString(_retour_type) == true ? _retour : {..._retour, _retour_type: '_retour_type was not from type string'};
        return _retour;
    }
    return true;
}

/**
 * @function _afetch Asynchronously await the return of the fetch operation
 * 
 * @param {string} _url - URL to fetch
 * @param {string} _method - Method to use
 * @param {Object|null} _body - Body if needed
 * @param {string} _body.test - Test text
 * @param {function|null} _onSuccess - Function to execute on success of both fetch and returned status
 * @param {function|null} _onError - Function to execute on wether fetch fail or error returned status
 * @param {Element|null} _elem - Elem attached to that action
 * @param {"json"|"blob"|"text"} _retour_type - Return type of back-office
 * @returns {Promise<boolean>|Promise<Object>} Wether the all process worked or not
*/
const _afetch = async (
    _url = "/",
    _method = "GET",
    _body = {},
    _onSuccess = null,
    _onError = null,
    _elem = null,
    _retour_type = "json",
) => {
    if(
        isString(_url) &&
        isString(_method) &&
        isObject(_body) &&
        (isFunction(_onSuccess) || isNull(_onSuccess)) &&
        (isFunction(_onError) || isNull(_onError)) &&
        (isNull(_elem) || isElement(_elem)) &&
        isString(_retour_type)
    ) {
        let vars = {
            method: _method,
            headers: new Headers({}),
            mode: 'cors',
            cache: 'default',
        };
        if(_method != "GET") {
            vars = {...vars, body: JSON.stringify(_body)};
        }
        if(!_body.req_id) {
            _body.req_id = chaineAleatoire(8);
        }
        return await fetch(_url, vars)
        .then((res) => {
            switch(_retour_type) {
                case 'text':
                    // _log("text");
                    return res.text(); // Au cas où
                case 'blob':
                    // _log("blob");
                    return res.blob(); // TODO - Pour plus tard, upload et download de gros fichiers
                case 'json':
                default:
                    // _log("json");
                    return res.json();
            }
        })
        .then((response) => {
            if(isFunction(_onSuccess)) {
                _onSuccess(_elem, response);
                return true;
            }
            else {
                window.sessionStorage.setItem(_body.req_id + '_status', 'success');
                window.sessionStorage.setItem(_body.req_id + '_elem', _elem);
                window.sessionStorage.setItem(_body.req_id + '_res', JSON.stringify(response));
            }
            return false;
        })
        .catch((err) => {
            _trace(err);
            _error(err);
            if(isFunction(_onError)) {
                _onError(_elem, err);
            }
            else {

            }
            window.sessionStorage.setItem(_body.req_id + '_status', 'error');
            window.sessionStorage.setItem(_body.req_id + '_elem', _elem);
            window.sessionStorage.setItem(_body.req_id + '_res', JSON.stringify(err));
            return false;
        })
    }
    else {
        let _retour = {};
        _retour = isString(_url) == true ? _retour : {..._retour, _url: '_url was not from type string'};
        _retour = isString(_method) == true ? _retour : {..._retour, _method: '_method was not from type string'};
        _retour = isObject(_body) == true ? _retour : {..._retour, _body: '_body was not from type object'};
        _retour = (isFunction(_onSuccess) || isNull(_onSuccess)) == true ? _retour : {..._retour, _onSuccess: '_onSuccess was not from type function or null'};
        _retour = (isFunction(_onError) || isNull(_onError)) == true ? _retour : {..._retour, _onError: '_onError was not from type function or null'};
        _retour = isElement(_retour_type) == true || isNull(_elem) ? _retour : {..._retour, _elem: '_elem was not from type Element or null'};
        _retour = isString(_retour_type) == true ? _retour : {..._retour, _retour_type: '_retour_type was not from type string'};
        return _retour;
    }
    return true;
}

// fetch(
//     "https://localhost/.well-known/mercure",
//     {
//         "method": "POST",
//         "cache": "default",
//         "headers": new Headers(),
//         "body": JSON.stringify({
//             "topic": "http://c7test.fr/sio/attribution_manager",
//             "data": {
//                 "from": "booking_manager",
//                 "action": "add_attributions",
//                 "value": [
//                     {"db_id": 5, "titre": "Attribution 5"},
//                     {"db_id": 8, "titre": "Attribution 8"},
//                 ]
//             }
//         })
//     }
// )

// function xrawFetch(
//     _url = "/",
//     _method = "GET",
//     _body = "",
//     _onSuccess = null,
//     _onError = null,
//     _elem = null,
//     _retour_type = "json"
// ) {
//     let vars = {
//         method: _method,
//         headers: new Headers({'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJtZXJjdXJlIjp7InB1Ymxpc2giOlsiKiJdfX0.obDjwCgqtPuIvwBlTxUEmibbBf0zypKCNzNKP7Op2UM'}),
//         mode: 'cors',
//         cache: 'default',
//     };
//     var formBody = [];
//     for (var property in _body) {
//         var encodedKey = encodeURIComponent(property);
//         var encodedValue = encodeURIComponent(_body[property]);
//         formBody.push(encodedKey + "=" + encodedValue);
//     }
//     formBody = formBody.join("&");
//     if(_method != "GET") {
//         vars = {...vars, body: formBody};
//     }
//     fetch(_url, vars)
//     .then((res) => {
//         switch(_retour_type) {
//             case 'text':
//                 // _log("text");
//                 return res.text(); // Au cas où
//             case 'blob':
//                 // _log("blob");
//                 return res.blob(); // TODO - Pour plus tard, upload et download de gros fichiers
//             case 'json':
//             default:
//                 // _log("json");
//                 return res.json();
//         }
//     })
//     .then((response) => {
//         if(isFunction(_onSuccess)) {
//             _onSuccess(_elem, response);
//             return true;
//         }
//         return false;
//     })
//     .catch((err) => {
//         _trace(err);
//         _error(err);
//         if(isFunction(_onError)) {
//             _onError(_elem, err);
//         }
//         else {

//         }
//         return false;
//     })
// }

// xrawFetch(
//     "https://localhost/.well-known/mercure",
//     "POST",
//     "topic=http://c7test.fr/sio/attribution_manager&data=123",
//     (res) => {console.log(res);},
//     (err) => {console.log(err);}
// )

// fetch(
//     "https://localhost/.well-known/mercure",
//     {
//         method: "POST",
//         mode: "cors",
//         headers: new Headers({"Authorization": "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJtZXJjdXJlIjp7InB1Ymxpc2giOlsiKiJdfX0.obDjwCgqtPuIvwBlTxUEmibbBf0zypKCNzNKP7Op2UM"}),
//         body: "topic=http://c7test.fr/sio/booking_manager&data=125"
//     }
// )
// .then(res => res.json())
// .then(res => console.log(res))
// .catch(err => console.log(err))